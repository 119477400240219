/* Style the header */
.header {
  background-color: whitesmoke;
}

/* Page content */
@media screen and (min-width: 768px) {
  .content {
    padding: 0px 24px;
  }
}