body {
    margin: 0;
    background-color: #eae7dc !important;
    font-family: 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    padding: 34px 0px 12px;
    min-height: 100vh;
}

.amplify-card {
    box-shadow: var(--amplify-shadows-large);
    min-width: 100%
}

input[type="date"],
input[type="datetime-local"] {
    display: inline-flex
}

@media screen and (min-width: 768px) {
    .amplify-card {
        min-width: 420px
    }
}

:root {
    --amplify-colors-brand-primary-80: #1a0f5e;
    --amplify-components-tabs-item-color: #1a0f5e;
    --amplify-components-button-link-active-color: #1a0f5e;
    --amplify-components-button-link-hover-color: rgba(26, 15, 94, 0.9);
    --amplify-components-tabs-item-hover-color: rgba(26, 15, 94, 0.9);
    --amplify-components-button-link-active-background-color: rgba(26, 15, 94, 0.2);
    --amplify-components-button-primary-hover-background-color: rgba(26, 15, 94, 0.8)
}